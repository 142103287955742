import { getField, updateField } from 'vuex-map-fields'
import axiosIns from '@/libs/axios'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    rekam: [],
    fields: [
      {
        key: 'tanggal_masuk'
      },
      {
        key: 'tanggal_keluar'
      },
      {
        key: 'nomor_pendaftaran'
      },
      {
        key: 'unit_tujuan'
      },
      {
        key: 'status'
      },
      {
        key: 'aksi'
      }
    ]
  },
  getters: {
    getField,
    rekam(state) {
      return state.rekam.map((item) => {
        return {
          ...item,
          tanggal_masuk: Vue.moment(item.masuk).format('DD-MMM-YY'),
          tanggal_keluar: item.keluar
            ? Vue.moment(item.keluar).format('DD-MMM-YY')
            : '',
          no_tiket: item.nomor_rekam
        }
      })
    }
  },
  mutations: {
    updateField,
    UPDATE_REKAM(state, rekam) {
      state.rekam = [...rekam]
    }
  },
  actions: {
    async getRekam({ commit }, norm) {
      try {
        const response = await axiosIns('/medicalrecord/' + norm)
        commit('UPDATE_REKAM', response.data.message)
      } catch (e) {
        console.log(e)
      }
    }
  }
}
