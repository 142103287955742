import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    allInvoiceDummy: [
      {
        id: 1,
        invoice: 'INV-00853',
        jumlah: '10.500',
        tanggal: '02-08-21',
        pengunjung: 'Zayn Ibrahim',
        status: 'UNPAID'
      },
      {
        id: 2,
        invoice: 'INV-00854',
        jumlah: '10.600',
        tanggal: '05-08-21',
        pengunjung: 'Zaynah Razan Nasr',
        status: 'PAID'
      },
      {
        id: 3,
        invoice: 'INV-00855',
        jumlah: '20.500',
        tanggal: '10-08-21',
        pengunjung: 'Firman Solihuddin',
        status: 'PAID'
      },
      {
        id: 4,
        invoice: 'INV-00857',
        jumlah: '15.500',
        tanggal: '09-08-21',
        pengunjung: 'Zayn Ibrahim',
        status: 'UNPAID'
      },
      {
        id: 5,
        invoice: 'INV-00863',
        jumlah: '15.500',
        tanggal: '02-08-21',
        pengunjung: 'Zayn Ibrahim',
        status: 'PARTIALLYPAID'
      }
    ],
    tampungBatch: [],
    keranjang: [],
    allInvoice: [],
    kategori: [],
    invoice: null
  },

  mutations: {
    setAllInvoice(state, payload) {
      state.allInvoice.push(payload)
    },
    setAllKeranjang(state, payload) {
      state.keranjang.push(payload)
    },
    setAllBatch(state, payload) {
      state.tampungBatch.push(payload)
    },
    removeKeranjang(state, payload) {
      var index = state.keranjang.findIndex((c) => c.id == payload.id)
      state.keranjang.splice(index, 1)
    },
    resetKeranjang(state) {
      state.keranjang = []
    },
    set_kategori_produk(state, payload) {
      state.kategori = payload
    },
    setInvoice(state, payload) {
      state.invoice = payload
    }
  },

  actions: {
    async loadDataInvoive(state) {
      await axios
        .get('Invoice')
        .then((response) => {
          state.commit('setAllInvoice', response.data.data.data)
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    async getKategori(state) {
      await axios
        .get('KategoriObatIndexSelect')
        .then((response) => {
          state.commit('set_kategori_produk', response.data.data)
        })
        .catch((error) => {
          console.log(error)
        })
    },

    // addObatNonRacik(state, payload){
    //     state.commit("setAllInvoice", payload);
    // },
    addKerangjang(state, payload) {
      state.commit('setAllKeranjang', payload)
    },
    tampungBatchProduct(state, payload) {
      state.commit('setAllBatch', payload)
    },
    deleteKerangjang(state, payload) {
      state.commit('removeKeranjang', payload)
    },
    resetKeranjang(state) {
      state.commit('resetKeranjang')
    },
    async loadInvoice(state, id) {
      if (state.invoice) return state.invoice
      try {
        state.commit('setInvoice', null)
        const response = await axios.get('/Invoice/' + id)
        state.commit('setInvoice', response.data.data)

        return response.data.data
      } catch (e) {
        console.error(e)
        return false
      }
    }
  },

  getters: {
    getAllInvoice: (state) => state.allInvoice,
    getAllBatch: (state) => state.tampungBatch,
    getAllKeranjang: (state) => state.keranjang,
    getKategori: (state) => state.kategori,
    invoice: (state) => state.invoice
  }
}
