import { $themeBreakpoints } from '@themeConfig'

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    crossOperation: [],
    version: process.env.PACKAGE_VERSION || ''
  },
  getters: {
    currentBreakPoint: (state) => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
    appVersion: (state) => {
      return state.version
    },
    globalOp: (state) => {
      const { crossOperation } = state
      const ret = {}
      crossOperation.forEach(([ name, op ]) => {
        ret[name] = op
      })
      return ret
    }
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    ADD_OPERATION(state, val) {
      const opName = val[0]
      // check if operation already exists
      const opIndex = state.crossOperation.findIndex(([ name ]) => name === opName)
      if (opIndex < 0)
        state.crossOperation.push(val)
    },
    REMOVE_OPERATION(state, v) {
      let val = v
      if (!Array.isArray(val)) val = [ val ]
      val.forEach((opName) => {
        const opIndex = state.crossOperation.findIndex(([ name ]) => name === opName)
        if (opIndex >= 0) state.crossOperation.splice(opIndex, 1)
      })
    }
  },
  actions: {
    addOperation({ commit }, val) {
      commit('ADD_OPERATION', val)
    },
    removeOperation({ commit }, val) {
      commit('REMOVE_OPERATION', val)
    }
  }
}
