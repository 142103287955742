import { getField, updateField } from 'vuex-map-fields'
import axiosIns from '@/libs/axios'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    summary: {},
    fields: {
      resep: [
        {
          key: 'tanggal_resep'
        },
        {
          key: 'no_resep'
        },
        {
          key: 'unit_tujuan'
        },
        {
          key: 'status'
        },
        {
          key: 'aksi'
        }
      ],
      pembayaran: [
        {
          key: 'tanggal_tagihan'
        },
        {
          key: 'no_tagihan'
        },
        {
          key: 'unit_tujuan'
        },
        {
          key: 'jumlah',
          class: 'text-right'
        },
        {
          key: 'status'
        },
        {
          key: 'aksi'
        }
      ]
    }
  },
  getters: {
    getField,
    resep(state) {
      if (!state.summary.eresep) return
      return state.summary.eresep.filter((v) => v.no_invoice && v.status).map((item) => {
        return {
          tanggal_resep: Vue.moment(item.tanggal_pelayanan).format('DD-MMM-YY'),
          no_resep: item.no_resep,
          unit_tujuan: item.unit_pelayanan,
          status: item.status?.deskripsi || ''
        }
      })
    },
    pembayaran(state) {
      if (!state.summary.pembayaran) return
      return state.summary.pembayaran.filter((v) => v.no_invoice && v.status).map((item) => {
        return {
          tanggal_tagihan: Vue.moment(item.status.created_at).format(
            'DD-MMM-YY'
          ),
          no_tagihan: item.no_invoice,
          unit_tujuan: item.detail[0].unit_pelayanan,
          status: item.status?.deskripsi || '',
          jumlah: item.nominal_total
        }
      })
    }
  },
  mutations: {
    updateField,
    UPDATE_SUMMARY(state, summary) {
      state.summary = { ...summary }
    }
  },
  actions: {
    async getSummary({ commit }, norm) {
      try {
        const response = await axiosIns('/summary/pasien/' + norm)
        commit('UPDATE_SUMMARY', response.data.message)
      } catch (e) {
        console.log(e)
      }
    }
  }
}
