import axios from '@/libs/axios'

export async function getPasien({ state, rootState, commit }) {
  if (state.pasien) {
    return state.pasien
  }

  try {
    let { data } = await this._vm.$axios.get('pasien')

    if (data.status === 'success') {
      commit('SET_PASIEN', data.data)

      return data.data
    } else {
      return []
    }
  } catch (err) {
    this.$bvToast.toast('Gagal mengambil data pasien', {
      title: 'Peringatan',
      variant: 'danger',
      solid: false
    })
  }
}

export async function getInstalasi({ state, rootState, commit }) {
  if (state.instalasi && state.instalasi.length) {
    return state.instalasi
  }

  try {
    let { data } = await axios.get('ruangan/instalasi')

    if (data.success === true && data.data.length > 0) {
      commit('SET_INSTALASI', data.data)

      return data.data
    } else {
      return null
    }
  } catch (err) {
    console.error(err)
  }
}

export async function getPenjamin({ state, rootState, commit }) {
  if (state.penjamin && state.penjamin.length) {
    return state.penjamin
  }

  const data = rootState.pasien.references['Jenis Kartu Asuransi / Penjamin']
  commit('SET_PENJAMIN', data)

  return data
}

export async function getPenjaminKelas({ state, rootState, commit }) {
  if (state.penjaminKelas && state.penjaminKelas.length) {
    return state.penjaminKelas
  }

  const data = rootState.pasien.references.Kelas
  commit('SET_PENJAMIN_KELAS', data)

  return data
}

export async function getPenjaminKecelakaan({ state, rootState, commit }) {
  if (state.penjaminKecelakaan) {
    return state.penjaminKecelakaan
  }

  const data = rootState.pasien.references['Jenis Penjamin Kecelakaan']
  commit('SET_PENJAMIN_KECELAKAAN', data)

  return data 
}

export async function getSHDK({ state, rootState, commit }) {
  if (state.statusHubunganKeluarga && state.statusHubunganKeluarga.length) {
    return state.statusHubunganKeluarga
  }

  const data = rootState.pasien.references['Status Hubungan Dalam Keluarga']
  commit('SET_SHDK', data)

  return data 
}

export async function getJK({ state, rootState, commit }) {
  if (state.jenisKelamin && state.jenisKelamin.length) {
    return state.jenisKelamin
  }

  const data = rootState.pasien.references['Jenis Kelamin']
  commit('SET_JK', data)

  return data 
}

export async function getPendidikan({ state, rootState, commit }) {
  if (state.pendidikan && state.pendidikan.length) {
    return state.pendidikan
  }

  const data = rootState.pasien.references.Pendidikan
  commit('SET_PENDIDIKAN', data)

  return data 
}

export async function updateReferences({ commit }, { referenceId, jenis, mutations }) {
  try {
    const { data } = await axios.get(`/referensi/${referenceId}?active=true`)

    if (data.data.length > 0) {
      if (mutations) {
        commit(mutations, data.data)
        localStorage[jenis] = JSON.stringify(data.data)
      }

      return data.data
    }

    return null
  } catch (err) {
    console.error(err)
    return null
  }
}

export async function getPekerjaan({ state, rootState, commit }) {
  if (state.pekerjaan && state.pekerjaan.length) {
    return state.pekerjaan
  }

  const data = rootState.pasien.references.Pekerjaan
  commit('SET_PEKERJAAN', data)

  return data 
}

export async function getJenisKontak({ state, rootState, commit }) {
  if (state.jenisKontak && state.jenisKontak.length) {
    return state.jenisKontak
  }

  const data = rootState.pasien.references['Jenis Kontak']
  commit('SET_JENIS_KONTAK', data)

  return data
}

export async function getProvinsi({ state, rootState, commit }) {
  if (state.provinsi) {
    return state.provinsi
  }

  const ls = localStorage.provinsi

  if (ls) {
    commit('SET_PROVINSI', JSON.parse(ls))

    return ls
  }

  try {
    const { data } = await axios.get('wilayah')

    if (data.success === true && data.data.length > 0) {
      commit('SET_PROVINSI', data.data)
      localStorage.provinsi = JSON.stringify(data.data)

      return data.data
    }
    
    return null
  } catch (err) {
    console.error(err)
  }
}

export async function getLoket({ state, rootState, commit }, payload) {
  if (state.loket && state.loket.length) {
    return state.loket
  }

  const ls = localStorage.loket

  if (ls && !payload?.refresh) {
    commit('SET_LOKET', JSON.parse(ls))

    return ls
  }

  try {
    const { data } = await axios.get('loket', {
      params: {
        filter: true,
        status: 1
      }
    })

    if (data.success === true && data.data.loket.length > 0) {
      commit('SET_LOKET', data.data.loket)
      localStorage.loket = JSON.stringify(data.data.loket)

      return data.data.loket
    }
    
    return null
  } catch (err) {
    console.error(err)
    return null
  }
}

export async function getBoxPattern({ state, rootState, commit }) {
  if (state.boxPattern && state.boxPattern.length) {
    return state.boxPattern
  }

  const ls = localStorage.boxPattern

  if (ls) {
    commit('SET_BOX_PATTERN', JSON.parse(ls))

    return ls
  }

  try {
    const { data } = await axios.get('BoxPattern')

    if (data.success === true && data.data.data.length > 0) {
      const result = data.data.data.map(box => ({
        id: box.id,
        name: `${box.nama  } - ${  box.qty_per_box}`,
        qty: box.qty_per_box
      }))
      commit('SET_BOX_PATTERN', result)
      localStorage.boxPattern = JSON.stringify(result)

      return result
    }
    
    return null
  } catch (err) {
    console.error(err)
  }
}

// export async function getGroupRuangan({ state, rootState, commit }) {
//   if (state.groupRuangan) {
//     return state.groupRuangan
//   }

//   const ls = localStorage.groupRuangan

//   if (ls) {
//     commit('SET_GROUP_RUANGAN', JSON.parse(ls))

//     return ls
//   }

//   try {
//     let { data } = await axios.get('group_ruangan')

//     if (data.success === true && data.data.length > 0) {
//       const result = data.data.map((group) => {
//         return {
//           id: group.id,
//           name: group.deskripsi
//         }
//       })
//       commit('SET_GROUP_RUANGAN', result)
//       localStorage.groupRuangan = JSON.stringify(result)

//       return result
//     } else {
//       return null
//     }
//   } catch (err) {
//     console.error(err)
//   }
// }

// export async function getParentGroupRuangan({ state, rootState, commit }) {
//   if (state.parentGroupRuangan) {
//     return state.parentGroupRuangan
//   }

//   const ls = localStorage.parentGroupRuangan

//   if (ls) {
//     commit('SET_PARENT_GROUP_RUANGAN', JSON.parse(ls))

//     return ls
//   }

//   try {
//     let { data } = await axios.get('parent_group_ruangan')

//     if (data.success === true && data.data.length > 0) {
//       const result = data.data.map((group) => {
//         return {
//           id: group.id,
//           name: group.deskripsi
//         }
//       })
//       commit('SET_PARENT_GROUP_RUANGAN', result)
//       localStorage.parentGroupRuangan = JSON.stringify(result)

//       return result
//     } else {
//       return null
//     }
//   } catch (err) {
//     console.error(err)
//   }
// }

export async function getAgama({ state, rootState, commit }) {
  if (state.agama) {
    return state.agama
  }

  const data = rootState.pasien.references.Agama
  commit('SET_AGAMA', data)

  return data
}

export async function getProfesi({ state, rootState, commit }) {
  if (state.profesi) {
    return state.profesi
  }

  const data = rootState.pasien.references['Jenis Profesi']
  commit('SET_PROFESI', data)

  return data
}

export async function getSmf({ state, rootState, commit }) {
  if (state.smf) {
    return state.smf
  }

  const data = rootState.pasien.references['Jenis Staf Medis Fungsional']
  commit('SET_SMF', data)

  return data
}

export async function getJenisIdentitas({ state, rootState, commit }) {
  if (state.jenisIdentitas) {
    return state.jenisIdentitas
  }

  const data = rootState.pasien.references['Jenis Kartu Identitas']
  commit('SET_JENIS_IDENTITAS', data)

  return data
}

export async function getJenisKunjungan({ state, rootState, commit }, payload) {
  if (state.jenisKunjungan && payload && !payload.refresh) {
    return state.jenisKunjungan
  }

  let data
  if (payload?.refresh) {
    const ob = { referenceId: 15, jenis: "jenisKunjungan", mutations: "SET_JENIS_KUNJUNGAN" }
    data = await updateReferences({ commit }, ob)
  } else {
    data = rootState.pasien.references['Jenis Kunjungan']
  }
  commit('SET_JENIS_KUNJUNGAN', data)

  return data
}

export async function getStatusKunjungan({ state, rootState, commit }) {
  if (state.statusKunjungan) {
    return state.statusKunjungan
  }

  const data = rootState.pasien.references['Status Aktifitas Kunjungan']
  commit('SET_STATUS_KUNJUNGAN', data)

  return data
}

export async function getStaffDokter({ state, rootState, commit }) {
  if (state.staffDokter) {
    return state.staffDokter
  }

  try {
    const { data } = await axios.get('dokter?limit=250')

    if (data.data.length > 0) {
      const dokters = data.data
        .filter(d => d.master_pegawai)
        .map(dokter => ({
          ...dokter,
          id_dokter: dokter.id,
          id: dokter.master_pegawai.id,
          name: `${dokter.master_pegawai.gelar_depan}. ${dokter.master_pegawai.nama}, ${dokter.master_pegawai.gelar_belakang}`.replaceAll(
            'null',
            ''
          )
        }))
      commit('SET_STAFF_DOKTER', dokters)

      return dokters
    }
    
    return null
  } catch (err) {
    console.error(err)
  }
}

export async function getStaffMedis({ state, rootState, commit }) {
  if (state.staffMedis) {
    return state.staffMedis
  }

  try {
    const { data } = await axios.get('pegawai/medis/list?limit=250')

    if (data.data.length > 0) {
      const medis = data.data.map(med => ({
        ...med,
        name: med.nama
      }))
      commit('SET_STAFF_MEDIS', medis)

      return medis
    } 
    
    return null
  } catch (err) {
    console.error(err)
  }
}

export async function getJenisRujukan({ state, rootState, commit }) {
  if (state.jenisRujukan) {
    return state.jenisRujukan
  }

  const data = rootState.pasien.references['Jenis Rujukan']
  commit('SET_JENIS_RUJUKAN', data)

  return data
}

export async function getPoliBpjs({ state, rootState, commit }) {
  if (state.poliBpjs) {
    return state.poliBpjs
  }

  const data = rootState.pasien.references['Ruangan Rujukan']
  commit('SET_POLI_BPJS', data)

  return data
}

export async function getProducts({ state, rootState, commit }) {
  if (state.products) {
    return state.products
  }

  const ls = localStorage.products

  if (ls) {
    commit('SET_PRODUCTS', JSON.parse(ls))

    return ls
  }

  try {
    const { data } = await axios.get('ProductIndexSelect')

    if (data.data.length > 0) {
      commit('SET_PRODUCTS', data.data)
      localStorage.products = JSON.stringify(data.data)

      return data.data
    } 
    
    return null
  } catch (err) {
    console.error(err)
  }
}

export async function updateProducts({ state, rootState, commit }) {
  try {
    const { data } = await axios.get('ProductIndexSelect')

    if (data.data.length > 0) {
      commit('SET_PRODUCTS', data.data)
      localStorage.products = JSON.stringify(data.data)

      return data.data
    } 
    
    return null
  } catch (err) {
    console.error(err)
  }
}

export async function getTindakan({ state, rootState, commit }, payload) {
  if (state.tindakan) {
    return state.tindakan
  }

  const ls = localStorage.tindakan

  if (ls && !payload?.refresh) {
    commit('SET_TINDAKAN', JSON.parse(ls))

    return ls
  }

  try {
    const { data } = await axios.get('tindakan?limit=1000')

    if (data.data.length > 0) {
      commit('SET_TINDAKAN', data.data)
      localStorage.tindakan = JSON.stringify(data.data)

      return data.data
    } 
    
    return null
  } catch (err) {
    console.error(err)
    return null
  }
}

export async function updateTindakan({ state, rootState, commit }) {
  try {
    const { data } = await axios.get('tindakan?limit=1000')

    if (data.data.length > 0) {
      commit('SET_TINDAKAN', data.data)
      localStorage.tindakan = JSON.stringify(data.data)

      return data.data
    } 
    return null
    
  } catch (err) {
    console.error(err)
  }
}

export async function getProdukGenerik({ state, rootState, commit }) {
  if (state.produkGenerik) {
    return state.produkGenerik
  }

  const data = rootState.pasien.references['Generik/Zat Aktif']
  commit('SET_PRODUK_GENERIK', data)

  return data
}

export async function getGolonganBarang({ state, rootState, commit }) {
  if (state.golonganBarang) {
    return state.golonganBarang
  }

  const data = rootState.pasien.references['Golongan Barang']
  commit('SET_GOLONGAN_BARANG', data)

  return data
}

export async function getAturanMinum({ state, _, commit }) {
  if (state.aturanMinum && state.aturanMinum.length > 0) {
    return state.aturanMinum
  }

  const data = await axios('referensi/41?active=true')
    .then(v => v.data?.data ?? [])
    .catch((e) => {
      console.error(e)
      return []
    })
  commit('SET_ATURAN_MINUM', data)

  return data
}

export async function getJenisRacik({ state, _, commit }) {
  if (state.jenisRacik && state.jenisRacik.length > 0) {
    return state.jenisRacik
  }

  const data = await axios('referensi/172?active=true')
    .then(v => v.data?.data ?? [])
    .catch((e) => {
      console.error(e)
      return []
    })
  commit('SET_JENIS_RACIK', data)
  return data
}

export async function getProdukObat({ state, rootState, commit }) {
  try {
    if (!state?.produkObat?.length && localStorage?.produk_obat) {
      commit('SET_PRODUK_OBAT', JSON.parse(localStorage.produk_obat))
    }

    const { data } = await axios.get('ProdukObatAll?withBatch=true')

    if (data.data.length > 0) {
      commit('SET_PRODUK_OBAT', data.data)
      localStorage.produk_obat = JSON.stringify(data.data)
    }
  } catch (err) {
    console.error(err)
  }
}