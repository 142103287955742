import axiosIns from '@/libs/axios'
import { arrayUniqueByKey } from '@/libs/helpers'

export default {
  namespaced: true,
  state: {
    provinsi: [],
    wilayah: [],
    kecamatan: [],
    kelurahan: []
  },
  mutations: {
    SET_PROVINSI(state, provinsi) {
      state.provinsi = arrayUniqueByKey(provinsi, 'value')
    },
    SET_WILAYAH(state, wilayah) {
      state.wilayah = arrayUniqueByKey(wilayah, 'value')
    },
    SET_KECAMATAN(state, kecamatan) {
      state.kecamatan = arrayUniqueByKey(kecamatan, 'value')
    },
    SET_KELURAHAN(state, kelurahan) {
      state.kelurahan = arrayUniqueByKey(kelurahan, 'value')
    }
  },
  actions: {
    init({ dispatch }) {
      dispatch('getProvinsi')
    },
    async getProvinsi({ commit }) {
      const response = await axiosIns.get('wilayah')
      commit('SET_PROVINSI', response.data.data)
    },
    async getWilayah({ commit, rootState }) {
      const response = await axiosIns.get(
        'wilayah/kota/' + rootState.pasien.form.provinsi.value,
        {
          params: {
            jenis: 2
          }
        }
      )
      commit('SET_WILAYAH', response.data.data)
    },
    async getKecamatan({ commit, rootState }) {
      const response = await axiosIns.get(
        'wilayah/kecamatan/' + rootState.pasien.form.wilayah.value,
        {
          params: {
            jenis: 3
          }
        }
      )
      commit('SET_KECAMATAN', response.data.data)
    },
    async getKelurahan({ commit, rootState }) {
      const response = await axiosIns.get(
        'wilayah/kelurahan/' + rootState.pasien.form.kecamatan.value,
        {
          params: {
            jenis: 4
          }
        }
      )
      commit('SET_KELURAHAN', response.data.data)
    }
  }
}
