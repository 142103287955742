import { format } from 'currency-formatter'

export default {
  data() {
    return {
      money: {
        thousands: '.',
        prefix: 'Rp. '
      }
    }
  },
  methods: {
    _(money) {
      const remove = `${this.money.thousands}${this.money.decimals}`.replace(
        /[.*+?^${}()|[\]\\]/g,
        '\\$&'
      )
      if (!money) return 0
      return money
        .substring(this.money.prefix.length)
        .replace(new RegExp(`[${remove}]`, 'g'), '')
    },
    formatMoney(money) {
      return format(money, {
        symbol: 'Rp.',
        thousand: '.',
        precision: 0,
        format: '%s %v'
      })
    },
    toNumber(str) {
      const n = parseInt(str, 10)
      if (Number.isNaN(n) || !n) return 0
      return n
    }
  }
}
