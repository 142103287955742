import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    data: null
  },

  mutations: {
    setData(state, payload) {
      state.data = payload
    }
  },

  actions: {
    data(state, payload) {
      state.commit('setData', payload)
    }
  },

  getters: {
    getData: (state) => state.data
  }
}
