import { RouterTabRoutes } from 'vue-router-tab'
export default [
  {
    path: '/',
    name: 'dashboard',
    component: () => import('@/views/Dashboard.vue'),
    meta: {
      contentClass: 'dashboard-application',
      title: 'Home',
      key: 'fullPath',
      resource: 'dashboard',
      closable: false
    },
    // children: [
    //   ...RouterTabRoutes,
    //   {
    //     path: '/apps/home', // the same with parent route
    //     name: 'apps-home',
    //     component: () => import('@/views/apps/home/Home.vue'),
    //     meta: {
    //       title: 'Home'
    //     }
    //   }
    // ]
  }
]
