import { toTitleString, urlToObject } from '@/libs/helpers'
import { getField, updateField } from 'vuex-map-fields'
import axiosIns from '@/libs/axios'
import { serialize } from 'object-to-formdata'

export default {
  namespaced: true,
  state: {
    fields: [
      {
        key: 'jenis_dokumen'
      },
      {
        key: 'expired_date'
      },
      {
        key: 'status'
      },
      {
        key: 'aksi'
      }
    ],
    jenisDokumen: [
      {
        text: 'KTP',
        value: 'ktp'
      },
      {
        text: 'Akta Kelahiran',
        value: 'akta_kelahiran'
      },
      {
        text: 'SIM',
        value: 'sim'
      },
      {
        text: 'BPJS',
        value: 'bpjs'
      },
      {
        text: 'NPWP',
        value: 'npwp'
      },
      {
        text: 'Lainnya',
        value: 'lainnya'
      }
    ],
    status: [
      {
        value: 1,
        text: 'Aktif'
      },
      {
        value: 0,
        text: 'Tidak Aktif'
      }
    ],
    detailDokumen: {},
    dokumen: []
  },
  getters: {
    getField,
    dokumen(state) {
      return state.dokumen.map((dokumen) => ({
        ...dokumen,
        jenis_dokumen: toTitleString(dokumen.type),
        status: state.status.find((status) => status.value == dokumen.status)
          .text
      }))
    }
  },
  mutations: {
    updateField,
    UPDATE_DOKUMEN(state, dokumen) {
      state.dokumen = [...dokumen]
    },
    async UPDATE_DETAIL_DOKUMEN(state, dokumen) {
      state.detailDokumen = {
        ...dokumen,
        file: await urlToObject(dokumen.file_url, dokumen.filename)
      }
    }
  },
  actions: {
    async getDokumen({ commit }, norm) {
      try {
        const response = await axiosIns.get('/dokumen-pasien/' + norm)
        commit('UPDATE_DOKUMEN', response.data.data)
      } catch (e) {
        console.error(e)
      }
    },
    async getDetailDokumen({ commit }, { norm, id }) {
      try {
        commit('UPDATE_DETAIL_DOKUMEN', {})
        const response = await axiosIns.get(
          '/dokumen-pasien/' + norm + '/' + id
        )
        commit('UPDATE_DETAIL_DOKUMEN', response.data.data)
      } catch (e) {
        console.error(e)
      }
    },
    async saveDokumen({ state, dispatch }, { norm }) {
      try {
        const dokumen = serialize({
          ...state.detailDokumen,
          jenis_dokumen: state.detailDokumen.type
        })
        await axiosIns.post('/dokumen-pasien/' + norm, dokumen)
      } catch (e) {
        console.log(e)
      } finally {
        dispatch('getDokumen', norm)
      }
    },
    async updateDokumen({ state, dispatch }, { norm }) {
      try {
        const dokumen = serialize({
          jenis_dokumen: state.detailDokumen.type,
          file: state.detailDokumen.file,
          expired_date: state.detailDokumen.expired_date,
          status: state.detailDokumen.status
        })
        await axiosIns.post(
          '/dokumen-pasien/' +
            norm +
            '/' +
            state.detailDokumen.id +
            '?_method=PUT',
          dokumen
        )
      } catch (e) {
        console.log(e)
      } finally {
        dispatch('getDokumen', norm)
      }
    }
  }
}
