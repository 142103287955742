import { getField, updateField } from 'vuex-map-fields'
import axiosIns from '@/libs/axios'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    kunjungan: [],
    fields: [
      {
        key: 'tanggal'
      },
      {
        key: 'no_tiket'
      },
      {
        key: 'unit_tujuan'
      },
      {
        key: 'status'
      },
      {
        key: 'aksi'
      }
    ]
  },
  getters: {
    getField,
    kunjungan(state) {
      return state.kunjungan.map((item) => {
        return {
          ...item,
          tanggal: Vue.moment(item.tanggal).format('DD-MMM-YY'),
          no_tiket: item.nomor_kunjungan
        }
      })
    }
  },
  mutations: {
    updateField,
    UPDATE_KUNJUNGAN(state, kunjungan) {
      state.kunjungan = [...kunjungan]
    }
  },
  actions: {
    async getKunjungan({ commit }, norm) {
      try {
        const response = await axiosIns('/kunjungan_by_norm/' + norm)
        commit('UPDATE_KUNJUNGAN', response.data.message)
      } catch (e) {
        console.log(e)
      }
    }
  }
}
