import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import {
  isUserLoggedIn,
  getUserData,
  getHomeRouteForLoggedInUser
} from '@/auth/utils'
import apps from './routes/apps'
import dashboard from './routes/dashboard'
import { useAuthStore } from '@/pinia/auth'
// import pages from './routes/pages'
// import dashboard from '@/views/Dashboard.vue'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...apps,
    ...dashboard,
    // ...pages,
    {
      path: '*',
      redirect: 'error-404'
    }
  ]
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  const canNavigate = to.matched.some((route) => {
    const auth = useAuthStore()
    if (!isLoggedIn && route.name === 'auth-login') {
      return true
    }
    return auth.ableTo(route.meta.action || 'read', route.meta.resource)
  })

  if (!canNavigate) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next('/')
  }

  if (to.meta.keepAlive === undefined) {
    to.meta.keepAlive = true
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
