export function SET_PASIEN(state, payload) {
  state.pasien = payload
}

export function SET_INSTALASI(state, payload) {
  state.instalasi = payload
}

export function SET_PENJAMIN(state, payload) {
  state.penjamin = payload
}

export function SET_PENJAMIN_KELAS(state, payload) {
  state.penjaminKelas = payload
}

export function SET_PENJAMIN_KECELAKAAN(state, payload) {
  state.penjaminKecelakaan = payload
}

export function SET_SHDK(state, payload) {
  state.statusHubunganKeluarga = payload
}

export function SET_JK(state, payload) {
  state.jenisKelamin = payload
}

export function SET_PENDIDIKAN(state, payload) {
  state.pendidikan = payload
}

export function SET_PEKERJAAN(state, payload) {
  state.pekerjaan = payload
}

export function SET_JENIS_KONTAK(state, payload) {
  state.jenisKontak = payload
}

export function SET_PROVINSI(state, payload) {
  state.provinsi = payload
}

export function SET_LOKET(state, payload) {
  state.loket = payload
}

export function SET_BOX_PATTERN(state, payload) {
  state.boxPattern = payload
}

export function SET_GROUP_RUANGAN(state, payload) {
  state.groupRuangan = payload
}

export function SET_PARENT_GROUP_RUANGAN(state, payload) {
  state.parentGroupRuangan = payload
}

export function SET_AGAMA(state, payload) {
  state.agama = payload
}

export function SET_SMF(state, payload) {
  state.smf = payload
}

export function SET_PROFESI(state, payload) {
  state.profesi = payload
}

export function SET_JENIS_IDENTITAS(state, payload) {
  state.jenisIdentitas = payload
}

export function SET_JENIS_KUNJUNGAN(state, payload) {
  state.jenisKunjungan = payload
}

export function SET_STATUS_KUNJUNGAN(state, payload) {
  state.statusKunjungan = [
    {
      title: 'Semua Status Kunjungan',
      value: 0
    },
    ...payload
  ]
}

export function SET_STAFF_DOKTER(state, payload) {
  state.staffDokter = payload
}

export function SET_STAFF_MEDIS(state, payload) {
  state.staffMedis = payload
}

export function SET_JENIS_RUJUKAN(state, payload) {
  state.jenisRujukan = payload
}

export function SET_POLI_BPJS(state, payload) {
  state.poliBpjs = payload
}

export function SET_PRODUCTS(state, payload) {
  state.products = payload
}

export function SET_TINDAKAN(state, payload) {
  state.tindakan = payload
}

export function SET_PRODUK_GENERIK(state, payload) {
  state.produkGenerik = payload
}

export function SET_GOLONGAN_BARANG(state, payload) {
  state.golonganBarang = payload
}

export function SET_ATURAN_MINUM(state, payload) {
  state.aturanMinum = payload
}

export function SET_JENIS_RACIK(state, payload) {
  state.jenisRacik = payload
}

export function SET_PRODUK_OBAT(state, payload) {
  state.produkObat = payload
}
