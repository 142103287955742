import sls from '@/libs/secure-ls'

const defaultObject = {
  type: 'object',
  default: '{}'
}

const defaultString = {
  type: 'string',
  default: ''
}

const defaultNumber = {
  type: 'number',
  default: '0'
}

class SecureStorage {
  setSecuredFields(fields) {
    this.securedFields = fields
  }

  getItem(key) {
    const sField = this.securedFields.filter((v) => v.name === key)
    if (sField.length) {
      let [mField] = sField
      switch (mField.type) {
        case 'object':
          mField = Object.assign(defaultObject, mField)
          break
        case 'number':
          mField = Object.assign(defaultNumber, mField)
          break
        default:
          mField = Object.assign(defaultString, mField)
          break
      }
      try {
        return sls.get(key) ? sls.get(key) : mField.default
      } catch (error) {
        this.removeItem(key)
        return sField.default
      }
    }
    return localStorage.getItem(key)
  }

  setItem(key, value) {
    const sField = this.securedFields.filter((v) => v.name === key)
    if (sField.length) {
      sls.set(key, value)
      return
    }
    localStorage.setItem(key, value)
  }

  removeItem(key) {
    localStorage.removeItem(key)
    return this
  }

  clear() {
    localStorage.clear()
    return this
  }
}

export default new SecureStorage()
