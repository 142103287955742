import Vue from 'vue'

// axios
import axios from 'axios'
import router from '@/router'
import Dexie from 'dexie'
import sha256 from 'crypto-js/sha256';
import { useAuthStore } from '@/pinia/auth';

const axiosIns = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})

const db = new Dexie('myDatabase');
db.version(1).stores({
  axiosCache: 'hash, result, expiresAt'
});

const CACHE_TTL = 60 * 5 * 1000;
const cacheMethod = ['get'];

const doNotCache = ['kunjungan', 'kunjungan/\\d+', 'pasien/search', 'obat/search'];

axiosIns.interceptors.request.use(async (request) => {
  const urlWithoutParams = request.url.split('?')[0];
  for(const item of doNotCache) {
    if (new RegExp(item).test(urlWithoutParams)) {
      return request
    }
  }
  if (!cacheMethod.includes(request.method)) {
    return request
  }
  if (request.headers['X-CACHE-INVALIDATE']) {
    await invalidate(request.url)
    return request
  }
  const hash = await digestMessage(request.url);
  const cached = await db.axiosCache.get({hash})
  if (!cached) {
    return request
  }
  if (Date.now() > cached.expiresAt) {
    try {
      await db.axiosCache.delete({hash});
    } catch (error) {
      // do nothing
    }
    return request
  }
  const result = JSON.parse(await decompressString(cached.result));
  
  return Promise.resolve({
    ...request,
    data: result
  });
});

const baseResponseInterceptor = axiosIns.interceptors.response.use(
  async (response) => {
    const urlWithoutParams = response.config.url.split('?')[0];

    if (response.config.url.endsWith('logout')) {
      db.axiosCache.clear();
      return response
    }

    for(const item of doNotCache) {
      if (new RegExp(item).test(urlWithoutParams)) {
        return response
      }
    }
    if (!cacheMethod.includes(response.config.method)) {
      return response
    }
    if (response.headers['Cache-Control'] === 'no-cache') {
      return response
    }
    let ttl = CACHE_TTL;
    if (response.config.headers['X-CACHE-TTL']) {
      ttl = parseInt(response.config.headers['X-CACHE-TTL']) * 1000
    }
    const hash = await digestMessage(response.config.url);
    const result = await compressString(response.data);
    const expiresAt = Date.now() + ttl;
    db.axiosCache.put({hash, result, expiresAt}, hash);
    return response
  },
  (error) => {
    if (error.response?.status === 401 && error.response?.data?.message === 'Unauthenticated.') {
      axiosIns.interceptors.response.eject(baseResponseInterceptor)
      const authStore = useAuthStore();
      authStore.signOut();
      db.axiosCache.clear();
      if (router.currentRoute.name !== 'auth-login') {
        router.push({ name: 'auth-login' })
      }
    }
    return Promise.reject(error)
  }
)

async function digestMessage(message) {
  return btoa(sha256(message));
}

async function compressString(inputData) {
  const input = JSON.stringify(inputData);
  const encoder = new TextEncoder();
  const data = encoder.encode(input);

  const cs = new CompressionStream('gzip');
  const writer = cs.writable.getWriter();
  writer.write(data);
  writer.close();

  const compressedStream = cs.readable;
  const compressedArrayBuffer = await new Response(compressedStream).arrayBuffer();
  const compressedData = new Uint8Array(compressedArrayBuffer);

  let binary = '';
  compressedData.forEach(byte => {
      binary += String.fromCharCode(byte);
  });
  return btoa(binary);
}

async function decompressString(inputData) {
  const binaryString = atob(inputData);
  const compressedData = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
      compressedData[i] = binaryString.charCodeAt(i);
  }
  const ds = new DecompressionStream('gzip');
  const writer = ds.writable.getWriter();
  writer.write(compressedData);
  writer.close();

  const decompressedStream = ds.readable;
  const decompressedArrayBuffer = await new Response(decompressedStream).arrayBuffer();
  const decoder = new TextDecoder();

  return decoder.decode(decompressedArrayBuffer);
}

export function setToken(token) {
  axiosIns.defaults.headers.common.Authorization = 'Bearer ' + token
}

export async function invalidate(url) {
  const hash = await digestMessage(url);
  try {
    if (await db.axiosCache.get({hash}))
      await db.axiosCache.delete({hash});
  } catch (e) {
    // do nothing
  }
}

Vue.prototype.$http = axiosIns

export default axiosIns
