<template>
  <div class="preview" :style="style"></div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      default: '/no-image.jpg'
    }
  },
  computed: {
    style() {
      return {
        background: `center / contain no-repeat url("${this.url}")`
      }
    }
  }
}
</script>

<style>
.preview {
  width: 100%;
  height: 150px;
  border: 0.5px solid #d8d6de;
}
</style>
