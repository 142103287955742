export default {
  'Agama': 1,
  'Jenis Kelamin': 2,
  'Pendidikan': 3,
  'Pekerjaan': 4,
  'Status Perkawinan': 5,
  'Golongan Darah': 6,
  'Status Hubungan Dalam Keluarga': 7,
  'Jenis Kontak': 8,
  'Jenis Kartu Identitas': 9,
  'Jenis Kartu Asuransi / Penjamin': 10,
  'Jenis Pemberi Pelayanan Kesehatan': 11,
  'Jenis Peserta Isteri Suami Anak': 12,
  'Status Pasien': 13,
  'Status Pegawai': 14,
  'Jenis Kunjungan': 15,
  'Bank': 16,
  'Jenis Kartu Debit / Kredit': 17,
  'Jabatan': 18,
  'Kelas': 19,
  'Status Ruang Kamar Tidur': 20,
  'Status Reservasi': 21,
  'Status Kunjungan': 22,
  'Status Rujukan': 23,
  'Status Ambil Pasien': 24,
  'Status (Lainnya)': 25,
  'Jenis Staf Medis Fungsional': 26,
  'Jenis Cetak': 27,
  'Kepemilikan Tempat Pelayanan Kesehatan': 28,
  'Jenis Pelayanan Kesehatan': 29,
  'Jenis Tarif': 30,
  'Status Aktifitas Kunjungan': 31,
  'Jenis Petugas Medis': 32,
  'Kelompok Umur': 33,
  'Jenis Pembayaran': 34,
  'Satuan Laboratorium': 35,
  'Jenis Profesi': 36,
  'Jenis Item Paket': 37,
  'Merk/Produsen': 39,
  'Jenis Status Layanan Farmasi': 40,
  'Aturan Pakai': 41,
  'Generik/Zat Aktif': 42,
  'Group User': 43,
  'Kelompok Operasi': 44,
  'Cara Keluar': 45,
  'Keadaan Keluar': 46,
  'Jenis Resep': 47,
  'Ya / Tidak': 48,
  'Jenis Tagihan': 49,
  'Jenis Transaksi Pembayaran Tagihan': 50,
  'Flow': 51,
  'Jenis Anastesi': 52,
  'Golongan Operasi': 53,
  'Jenis ICD': 54,
  'Jenis Penggunaan Obat': 55,
  'Jenis Deposit': 56,
  'Alasan Pembatalan Kunjungan': 57,
  'Jenis Pembatalan Retur': 58,
  'Alasan Pembatalan Retur': 59,
  'Jenis Penerimaan': 63,
  'Jenis Pemeriksaan PA': 66,
  'Alasan Pembatalan Penerimaan Barang': 67,
  'Periode Perencanaan': 68,
  'Jenis Pengguna': 69,
  'Ruangan Rujukan': 70,
  'Metode Skala Nyeri': 71,
  'Jenis Onset Penilaian Nyeri': 72,
  'Jenis Pasien': 73,
  'Kategori Tindakan': 74,
  'Jenis Rawat Inap': 75,
  'Jenis Absen': 77,
  'Jenis Pemberian Obat': 78,
  'Jenis Kemasan Racikan': 79,
  'Jenis Penjamin Kecelakaan': 80,
  'Nama Bulan': 83,
  'Petunjuk Racikan': 84,
  'Jenis Rujukan': 86,
  'Jenis Operasi': 87,
  'Status Kepegawaian': 88,
  'Payroll Penambah': 89,
  'Payroll Pengurang': 90,
  'Jenis Triwulan': 91,
  'SITT - Jenis Perujuk': 92,
  'SITT - Jenis Diagnosis': 93,
  'SITT - Klasifikasi Lokasi Anatomi': 94,
  'SITT - Klasifikasi Riwayat Pengobatan': 95,
  'SITT - Klasifikasi Status HIV': 96,
  'SITT - Scoring TB Anak (0 - 13)': 97,
  'SITT - Scoring TB Anak 5': 98,
  'SITT - Scoring TB Anak 6': 99,
  'SITT - Paduan OAT': 100,
  'SITT - Sumber Obat': 101,
  'SITT - Hasil Pemeriksaan Foto Toraks': 102,
  'SITT - Sebelum Pengobatan Hasil Mikroskopis': 103,
  'SITT - Sebelum Pengobatan TCM': 104,
  'SITT - Sebelum Pengobatan Biakan': 105,
  'SITT - Akhir Bulan Ke 2 Hasil Mikroskopis': 106,
  'SITT - Bulan Ke 3 Hasil Mikroskopis': 107,
  'SITT - Bulan Ke 5 Hasil Mikroskopis': 108,
  'SITT - Akhir Pengobatan Hasil Mikroskopis': 109,
  'SITT - Hasil Akhir Pengobatan': 110,
  'SITT - Hasil Tes HIV': 111,
  'SITT - PPK': 112,
  'SITT - ART': 113,
  'SITT - DM': 114,
  'SITT - Terapi DM': 115,
  'SITT - Dipindahkan ke TB.03 RO': 116,
  'SITT - Status Pengobatan': 117,
  'SITT - Toraks tidak dilakukan': 118,
  'Jenis Diskon': 126,
  'Aturan Perhitungan Akomodasi': 127,
  'Jenis Perhitungan Penjamin Tagihan': 130,
  'Daftar Suku': 140,
  'Jenis Bridge': 145,
  'Jenis Info Teks': 150,
  'Jasa Raharja': 151,
  'Status Pediatric': 155,
  'Prioritas': 158,
  'Status Antrian Tempat Tidur': 159,
  'Golongan Barang': 173,
  'Grup Tindakan Lab': 174,
  'Sub-Grup Tindakan Lab': 175,
  'Periode Laporan': 176,
  'Status Pelunasan Tempo': 177
}
