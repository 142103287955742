var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.visible)?_c('b-col',{staticClass:"mb-1",attrs:{"sm":"12","md":"12","xl":"12"}},[_c('b-card-text',[_c('Transition',{attrs:{"name":"fold","mode":"out-in"}},[_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":_vm.tandaVital ? 4 : 6}},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('b-form-group',{staticStyle:{"flex":"1"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Jenis Kunjungan"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"disabled":_vm.disabled,"options":[{ value: 'sakit', text: 'Sakit' }, { value: 'sehat', text: 'Sehat' }]},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("Jenis Kunjungan")])]},proxy:true}],null,true),model:{value:(_vm.jnsKunj),callback:function ($$v) {_vm.jnsKunj=$$v},expression:"jnsKunj"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4146786382)})],1),(_vm.isRajal)?_c('b-form-group',{staticClass:"ml-1"},[(!_vm.disabled)?_c('b-form-checkbox',{model:{value:(_vm.promotif),callback:function ($$v) {_vm.promotif=$$v},expression:"promotif"}},[_c('h5',[_vm._v("Promotif")])]):_vm._e()],1):_vm._e()],1),_c('b-form-group',[_c('validation-provider',{attrs:{"rules":"required","name":"Keluhan"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"placeholder":"Keluhan","rows":_vm.isRajal ? 1 : 2,"disabled":_vm.disabled},model:{value:(_vm.keluhan),callback:function ($$v) {_vm.keluhan=$$v},expression:"keluhan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3463446911)})],1)],1)]),_c('b-col',{attrs:{"sm":"12","md":_vm.tandaVital ? 4 : 6}},[_c('div',{staticClass:"d-flex flex-row"},[_c('b-form-group',[_c('validation-provider',{attrs:{"rules":"required|numeric|min_value:30|max_value:250","name":"Tinggi Badan"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('b-input-group-text',[_c('strong',[_vm._v("cm")])])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"placeholder":"Tinggi Badan","type":"number","disabled":_vm.disabled},model:{value:(_vm.tinggi_badan),callback:function ($$v) {_vm.tinggi_badan=$$v},expression:"tinggi_badan"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2991276078)})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"rules":"required|numeric|min_value:2|max_value:300","name":"Berat Badan"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('b-input-group-text',[_c('strong',[_vm._v("kg")])])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"placeholder":"Berat Badan","type":"number","disabled":_vm.disabled},model:{value:(_vm.berat_badan),callback:function ($$v) {_vm.berat_badan=$$v},expression:"berat_badan"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3407533292)})],1),(!_vm.tandaVital)?_c('b-form-group',[_c('b-input-group',{scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('b-input-group-text',[_c('strong',[_vm._v("kg/m"),_c('sup',[_vm._v("2")])])])]},proxy:true}],null,false,3293889154)},[_c('b-form-input',{attrs:{"placeholder":"IMT","type":"number","readonly":""},model:{value:(_vm.imt),callback:function ($$v) {_vm.imt=$$v},expression:"imt"}})],1)],1):_vm._e()],1),(_vm.tandaVital)?_c('b-form-group',[_c('b-input-group',{scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('b-input-group-text',[_c('strong',[_vm._v("kg/m"),_c('sup',[_vm._v("2")])])])]},proxy:true}],null,false,3293889154)},[_c('b-form-input',{attrs:{"placeholder":"IMT","type":"number","readonly":""},model:{value:(_vm.imt),callback:function ($$v) {_vm.imt=$$v},expression:"imt"}})],1)],1):_vm._e(),_c('b-form-group',[_c('validation-provider',{attrs:{"rules":"required|numeric|min_value:25|max_value:300","name":"Lingkar Perut"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('b-input-group-text',[_c('strong',[_vm._v("cm")])])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"placeholder":"Lingkar Perut","type":"number","disabled":_vm.disabled},model:{value:(_vm.lingkar_perut),callback:function ($$v) {_vm.lingkar_perut=$$v},expression:"lingkar_perut"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3196167918)})],1),_c('b-form-group',[(!_vm.disabled)?_c('b-form-checkbox',{model:{value:(_vm.tandaVital),callback:function ($$v) {_vm.tandaVital=$$v},expression:"tandaVital"}},[_vm._v(" Tampilkan form tanda vital ")]):_vm._e()],1)],1),_c('b-col',{class:{hiddenWidth: !_vm.tandaVital},attrs:{"sm":"12","md":"4"}},[_c('div',{staticStyle:{"display":"grid","grid-template-columns":"1fr 1fr"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"rules":"numeric|min_value:40|max_value:250","name":"Sistole"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('b-input-group-text',[_c('strong',[_vm._v("mmHg")])])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"placeholder":"Sistole","type":"number","disabled":_vm.disabled},model:{value:(_vm.sistole),callback:function ($$v) {_vm.sistole=$$v},expression:"sistole"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3162736592)})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"rules":"numeric|min_value:30|max_value:180","name":"Diastole"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('b-input-group-text',[_c('strong',[_vm._v("mmHg")])])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"placeholder":"Diastole","type":"number","disabled":_vm.disabled},model:{value:(_vm.diastole),callback:function ($$v) {_vm.diastole=$$v},expression:"diastole"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,177521648)})],1)],1),_c('div',{staticStyle:{"display":"grid","grid-template-columns":"1fr 1fr"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"rules":"numeric|min_value:30|max_value:160","name":"Heart Rate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('b-input-group-text',[_c('strong',[_vm._v("bpm")])])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"placeholder":"Heart Rate","type":"number","disabled":_vm.disabled},model:{value:(_vm.heart_rate),callback:function ($$v) {_vm.heart_rate=$$v},expression:"heart_rate"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3014919199)})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"rules":"numeric|min_value:5|max_value:70","name":"Respiratory Rate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('b-input-group-text',[_c('strong',[_vm._v("/ Minute")])])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"placeholder":"Respiratory Rate","type":"number","disabled":_vm.disabled},model:{value:(_vm.resp_rate),callback:function ($$v) {_vm.resp_rate=$$v},expression:"resp_rate"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3028793291)})],1)],1)])],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }