export const arrayUniqueByKey = (array, key) => [
  ...new Map(array.map((item) => [item[key], item])).values()
]

export const objectClean = (object) => {
  let obj = Object.assign({}, object)
  Object.keys(obj).forEach(function (key, index) {
    if (typeof obj[key] == 'object' && !(obj[key] instanceof File)) {
      obj[key] = obj[key]?.value
    }
  })
  return obj
}

export const urlToObject = async (url, fileName) => {
  if (!url || !fileName) return
  const response = await fetch(url)
  const blob = await response.blob()
  const file = new File([blob], fileName, { type: blob.type })
  return file
}

export const toTitleString = (string) => {
  return string
    .replace(/([a-z])([A-Z])/g, function (allMatches, firstMatch, secondMatch) {
      return firstMatch + ' ' + secondMatch
    })
    .toLowerCase()
    .replace(/([ -_]|^)(.)/g, function (allMatches, firstMatch, secondMatch) {
      return (firstMatch ? ' ' : '') + secondMatch.toUpperCase()
    })
}
