export default function () {
  return {
    pasien: null,
    instalasi: null,
    penjamin: null,
    penjaminKelas: null,
    penjaminKecelakaan: null,
    statusHubunganKeluarga: null,
    jenisKelamin: null,
    pendidikan: null,
    pekerjaan: null,
    jenisKontak: null,
    provinsi: null,
    kabupaten: null,
    kecamatan: null,
    desa: null,
    loket: null,
    boxPattern: null,
    groupRuangan: null,
    parentGroupRuangan: null,
    agama: null,
    smf: null,
    profesi: null,
    jenisIdentitas: null,
    jenisKunjungan: null,
    statusKunjungan: null,
    staffDokter: null,
    staffMedis: null,
    jenisRujukan: null,
    poliBpjs: null,
    products: null,
    tindakan: null,
    produkGenerik: null,
    golonganBarang: null,
    aturanMinum: null,
    jenisRacik: null,
    produkObat: null
  }
}
