const DB_NAME = 'dekacare'
const DB_VERSION = 1
let DB

export async function initDb() {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, DB_VERSION)
    request.onerror = () => {
      reject(request.error)
    }
    request.onsuccess = () => {
      DB = request.result
      resolve(DB)
    }
    request.onupgradeneeded = () => {
      const db = request.result
      db.createObjectStore('opname')
    }
  })
}

export default {
  methods: {
    async idbSaveOpname(id, opname) {
      return new Promise((resolve, reject) => {
        const transaction = DB.transaction(['opname'], 'readwrite')
        const store = transaction.objectStore('opname')
        const request = store.put(opname, id)
        request.onerror = () => {
          reject(request.error)
        }
        request.onsuccess = () => {
          resolve(request.result)
        }
      })
    },
    async idbGetOpname(id) {
      return new Promise((resolve, reject) => {
        const transaction = DB.transaction(['opname'], 'readonly')
        const store = transaction.objectStore('opname')
        const request = store.get(id)
        request.onerror = () => {
          reject(request.error)
        }
        request.onsuccess = () => {
          resolve(request.result)
        }
      })
    }
  }
}