import Vue from 'vue'
import Vuex from 'vuex'

// Modules
// import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import createPersistedState from 'vuex-persistedstate'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import masterdata from './masterdata'
import pasien from './pasien'
import wilayah from './wilayah'
import wilayahIdentitas from './wilayahIdentitas'
import dokumenPasien from './dokumenPasien'
import jadwalPasien from './jadwalPasien'
import kunjunganPasien from './kunjunganPasien'
import rekamMedisPasien from './rekamMedisPasien'
import summaryPasien from './summaryPasien'
import penjualan from './penjualan'
import user from './user'
import eticket from './eticket'
import pegawai from './pegawai'
import ruangan from './ruangan'

Vue.use(Vuex)

const persistedStates = createPersistedState({
  key: 'dekacare',
  paths: ['pasien.references', 'eticket.data']
})

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    masterdata,
    pasien,
    wilayah,
    wilayahIdentitas,
    dokumenPasien,
    jadwalPasien,
    kunjunganPasien,
    rekamMedisPasien,
    summaryPasien,
    penjualan,
    user,
    eticket,
    pegawai,
    ruangan
    // 'app-ecommerce': ecommerceStoreModule,
  },
  strict: process.env.DEV,
  plugins: [persistedStates]
})
