<template>
  <b-col v-if="visible" sm="12" md="12" xl="12" class="mb-1">
    <b-card-text>
      <Transition name="fold" mode="out-in">
        <b-row>
          <b-col sm="12" :md="tandaVital ? 4 : 6">
            <div class="d-flex flex-column">
              <div class="d-flex align-items-center">
                <b-form-group style="flex: 1;">
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="Jenis Kunjungan"
                  >
                    <b-form-select
                      v-model="jnsKunj"
                      :disabled="disabled"
                      :options="[{ value: 'sakit', text: 'Sakit' }, { value: 'sehat', text: 'Sehat' }]"
                    >
                      <template #first>
                        <b-form-select-option :value="null" disabled>Jenis Kunjungan</b-form-select-option>
                      </template>
                    </b-form-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group v-if="isRajal" class="ml-1">
                  <b-form-checkbox v-model="promotif" v-if="!disabled">
                    <h5>Promotif</h5>
                  </b-form-checkbox>
                </b-form-group>
              </div>
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="Keluhan"
                >
                  <b-form-textarea
                    v-model="keluhan"
                    placeholder="Keluhan"
                    :rows="isRajal ? 1 : 2"
                    :disabled="disabled"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </b-col>
          <b-col sm="12" :md="tandaVital ? 4 : 6">
            <div class="d-flex flex-row">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  rules="required|numeric|min_value:30|max_value:250"
                  name="Tinggi Badan"
                >
                  <b-input-group>
                    <b-form-input
                      v-model="tinggi_badan"
                      placeholder="Tinggi Badan"
                      type="number"
                      :disabled="disabled"
                    />
                    <template #append>
                      <b-input-group-text><strong>cm</strong></b-input-group-text>
                    </template>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  rules="required|numeric|min_value:2|max_value:300"
                  name="Berat Badan"
                >
                  <b-input-group>
                    <b-form-input
                      v-model="berat_badan"
                      placeholder="Berat Badan"
                      type="number"
                      :disabled="disabled"
                    />
                    <template #append>
                      <b-input-group-text><strong>kg</strong></b-input-group-text>
                    </template>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group v-if="!tandaVital">
                <b-input-group>
                  <b-form-input
                    v-model="imt"
                    placeholder="IMT"
                    type="number"
                    readonly
                  />
                  <template #append>
                    <b-input-group-text><strong>kg/m<sup>2</sup></strong></b-input-group-text>
                  </template>
                </b-input-group>
              </b-form-group>
            </div>
            <b-form-group v-if="tandaVital">
              <b-input-group>
                <b-form-input
                  v-model="imt"
                  placeholder="IMT"
                  type="number"
                  readonly
                />
                <template #append>
                  <b-input-group-text><strong>kg/m<sup>2</sup></strong></b-input-group-text>
                </template>
              </b-input-group>
            </b-form-group>
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                rules="required|numeric|min_value:25|max_value:300"
                name="Lingkar Perut"
              >
                <b-input-group>
                  <b-form-input
                    v-model="lingkar_perut"
                    placeholder="Lingkar Perut"
                    type="number"
                    :disabled="disabled"
                  />
                  <template #append>
                    <b-input-group-text><strong>cm</strong></b-input-group-text>
                  </template>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="tandaVital" v-if="!disabled">
                Tampilkan form tanda vital
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col :class="{hiddenWidth: !tandaVital}" sm="12" md="4">
            <div style="display: grid; grid-template-columns: 1fr 1fr">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  rules="numeric|min_value:40|max_value:250"
                  name="Sistole"
                >
                  <b-input-group>
                    <b-form-input
                      v-model="sistole"
                      placeholder="Sistole"
                      type="number"
                      :disabled="disabled"
                    />
                    <template #append>
                      <b-input-group-text><strong>mmHg</strong></b-input-group-text>
                    </template>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  rules="numeric|min_value:30|max_value:180"
                  name="Diastole"
                >
                  <b-input-group>
                    <b-form-input
                      v-model="diastole"
                      placeholder="Diastole"
                      type="number"
                      :disabled="disabled"
                    />
                    <template #append>
                      <b-input-group-text><strong>mmHg</strong></b-input-group-text>
                    </template>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div style="display: grid; grid-template-columns: 1fr 1fr">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  rules="numeric|min_value:30|max_value:160"
                  name="Heart Rate"
                >
                  <b-input-group>
                    <b-form-input
                      v-model="heart_rate"
                      placeholder="Heart Rate"
                      type="number"
                      :disabled="disabled"
                    />
                    <template #append>
                      <b-input-group-text><strong>bpm</strong></b-input-group-text>
                    </template>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  rules="numeric|min_value:5|max_value:70"
                  name="Respiratory Rate"
                >
                  <b-input-group>
                    <b-form-input
                      v-model="resp_rate"
                      placeholder="Respiratory Rate"
                      type="number"
                      :disabled="disabled"
                    />
                    <template #append>
                      <b-input-group-text><strong>/ Minute</strong></b-input-group-text>
                    </template>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </b-col>
        </b-row>
      </Transition>
    </b-card-text>
  </b-col>
</template>

<script>
export default {
  name: 'PanelRekamMedis',
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: null
    },
    isRajal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      keluhan: '',
      tinggi_badan: null,
      berat_badan: null,
      lingkar_perut: null,
      imt: null,
      sistole: null,
      diastole: null,
      resp_rate: null,
      heart_rate: null,
      jnsKunj: null,
      tandaVital: false,
      promotif: false,
    }
  },
  computed: {
    allData() {
      return {
        kunjungan_sakit: this.jnsKunj === 'sakit',
        keluhan: this.keluhan,
        tinggi_badan: this.tinggi_badan,
        berat_badan: this.berat_badan,
        lingkar_perut: this.lingkar_perut,
        imt: this.imt,
        sistole: this.sistole,
        diastole: this.diastole,
        respiratory_rate: this.resp_rate,
        heart_rate: this.heart_rate,
        promotif: this.promotif && this.isRajal,
      }
    },
  },
  watch: {
    allData: {
      deep: true,
      handler() {
        this.$emit('input', this.allData)
      }
    },
    tinggi_badan() {
      this.calcBMI()
    },
    berat_badan() {
      this.calcBMI()
    },
    value: {
      handler() {
        this.setData()
      },
      deep: true
    }
  },
  created() {
    this.setData()
  },
  methods: {
    setData() {
      let kunj = null;
      if (this.value?.kunjungan_sakit === true) kunj = 'sakit'
      if (this.value?.kunjungan_sakit === false) kunj = 'sehat'
      this.jnsKunj = kunj
      this.keluhan = this.value?.keluhan
      this.tinggi_badan = this.value?.tinggi_badan
      this.berat_badan = this.value?.berat_badan
      this.lingkar_perut = this.value?.lingkar_perut
      this.imt = this.value?.imt
      this.sistole = this.value?.sistole
      this.diastole = this.value?.diastole
      this.heart_rate = this.value?.heart_rate
      this.resp_rate = this.value?.respiratory_rate
      this.promotif = this.value?.promotif
      this.tandaVital = this.value?.sistole ? true : false;
    },
    unsetData() {
      this.keluhan = ''
      this.tinggi_badan = null
      this.berat_badan = null
      this.lingkar_perut = null
      this.imt = null
      this.sistole = null
      this.diastole = null
      this.heart_rate = null
      this.resp_rate = null
      this.jnsKunj = null
      this.tandaVital = false
    },
    calcBMI() {
      if (!this.berat_badan || !this.tinggi_badan) {
        this.imt = null
        return
      }
      this.imt = ((this.berat_badan / (this.tinggi_badan * this.tinggi_badan)) * 10000).toFixed(1)
    },
    bmiStr() {
      if (!this.imt) return ''
      if (this.imt < 17) return 'Sangat Kurus'
      if (this.imt < 18.4) return 'Kurus'
      if (this.imt < 25.0) return 'Normal'
      if (this.imt < 27) return 'Gemuk'
      return 'Obesitas'
    },
  }
}
</script>

<style lang="scss" scoped>
.fold-enter-active,
.fold-leave-active {
  transition: all 0.25s;
}
.fold-enter,
.fold-leave-to {
  transform: scaleY(0) translateY(-100%);
  opacity: 0;
}
.hiddenWidth {
  overflow: none;
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
}
</style>